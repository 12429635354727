import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';

const FullBackground = ({ children, queryData }) => {
  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  let backgroundFluidImageStack = [];
  const { file } = useStaticQuery(graphql`
    query {
      file (childImageSharp: {fixed: {originalName: {eq: "default_background.jpg"}}}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  if(queryData !== undefined){
    backgroundFluidImageStack.push(queryData.background.fluid)
  }
  else{
    backgroundFluidImageStack.push(file.childImageSharp.fluid);
  }

  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundFluidImageStack}
      title="Fullscreen Background"
      id="fullscreenbg"
      role="img"
      aria-label="Fullscreen Background"
      preserveStackingContext={true}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </BackgroundImage>
  );
};

export default FullBackground;