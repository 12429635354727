/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./header"
import "./layout.css"
import "../../css/custom.css"
import StyledFullBackground from "./FullBackground";

const Layout = ({ children, queryData }) => {

  return (
    <>
      <StyledFullBackground queryData={queryData}>
        <div id="backgroundOpacier" style={{display: 'grid',  height:'100vh', background:'rgba(255, 255, 255, 0.5)'}}>
          <Header />
          <main>{children}</main>
          <footer style={{position: 'fixed', bottom: 0, fontSize: '12px', margin: '0 auto', width:'100%'}}>
            © {new Date().getFullYear()}, Chick Meal Prep
            {` `}.
            Developed by Andrés Badel
          </footer>
        </div>
      </StyledFullBackground>
    </>
  )
}

export default Layout
