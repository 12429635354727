import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState, Fragment } from "react";
import { Menu, Drawer } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

const Header = () => {

  const [drawerVisible, setDrawerVisible] = useState(false);

  const { contentfulHeader } = useStaticQuery(
    graphql`
      query {
        contentfulHeader {
          headerMenuItems {
            name
            url
          }
        }
      }
    `
  );

  function displayWindowSize(){
    var w = document.documentElement.clientWidth;

    if(w < 600){
      document.getElementById('menuDiv').style.display = 'none';
      document.getElementById('collapsedMenu').style.display = 'block';
    }
    else{
      document.getElementById('menuDiv').style.display = 'block';
      document.getElementById('collapsedMenu').style.display = 'none';
      setDrawerVisible(false);
    }
  }

  useEffect( () => {
      window.addEventListener("resize", displayWindowSize);
      displayWindowSize();
  }, []);

  const createMenu = () => {
    return contentfulHeader.headerMenuItems.map((elem, index)=>(
      <Menu.Item key={index} >
        <Link
          to={elem.url}
          style={{
            textDecoration: `none`,
            fontSize: '20px',
            color: '#593527',
            fontFamily: 'Lucida Console", Courier, monospace'
          }}
        >
          {elem.name}
        </Link>
      </Menu.Item>
    ));
  }

  return (
    
    <Fragment>
      <div id="menuDiv">
        <Menu id="menu" mode='horizontal' style={{height: '64px'}}>
            {createMenu()}
        </Menu>
      </div>
      <div id="collapsedMenu">
          <UnorderedListOutlined id='collapsableMenuIcon' onClick={() => {setDrawerVisible(true)}} />
          <Drawer
              title="ChickMealPrep"
              placement="left"
              closable={true}
              visible={drawerVisible}
              onClose={() => setDrawerVisible(false)}
              style={{backgroundColor: '#593527'}}
              >
              <Menu id="menu" mode='inline'>
                  {createMenu()}
              </Menu>
          </Drawer>
      </div>
    </Fragment>
  )
}

export default Header
